import { Component, NgZone } from '@angular/core';
import { Device } from '@capacitor/device';
import { StatusBar, Style } from '@capacitor/status-bar';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { AdMob } from '@capacitor-community/admob';
import { BackgroundGeolocationPlugin } from 'cordova-background-geolocation-plugin';
declare const BackgroundGeolocation: BackgroundGeolocationPlugin;
import { Geolocation } from '@capacitor/geolocation';
import { AlertController, Platform } from '@ionic/angular';
import { Dialog } from '@capacitor/dialog';
import { Storage } from '@capacitor/storage';
import { DataService } from './data.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private zone: NgZone,
    public alertCtrl: AlertController,
    public ds: DataService,
    public router: Router,
    public platform: Platform
  ) {
    this.initializeApp()
  }
  initializeApp() {
    //CHECK FOR DEEPLINK
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      console.log('event',event);
      this.zone.run(() => {
        const urlArr:string = event.url.split('/search/').pop();
        if(urlArr) {
          let slugs:any = urlArr.split('/');
          console.log(slugs);
          if(slugs.length > 1) {
            this.ds.deeplinkedSite.next({
              type: slugs[0],
              id: slugs[1]
            });
          } else {
            this.ds.deeplinkedSite.next({
              type: 'wells',
              id: slugs[0]
            });
          }
          
        }
      })
    })
    //INITIALIZE ADMOB
    AdMob.initialize({
      requestTrackingAuthorization: true,
      testingDevices: [],
      initializeForTesting: true,
    }).then(admob => {
      console.log('Admob Initialized',admob);
      //CHECK LOCATION AUTHORIZATION
      Geolocation.checkPermissions().then(permissionsState => {
        this.handleLocationPermissionsState(permissionsState);
      }).catch(err => {
        console.log('geo permissions err',err);
      })
    }).catch(err => {
      console.log('Admob Initialized Err',err);
    })
    Storage.get({key:this.ds.app+'settings'}).then(data => {
      if(data.value) {
        let settings = JSON.parse(data.value);
        for(let key in settings) this.ds.settings[key] = settings[key];
      } else this.saveSettings();
    }).catch(err => {
      this.saveSettings();
    })
  }
  saveSettings() {
    Storage.set({
      key: this.ds.app+'settings',
      value: JSON.stringify(this.ds.settings)
    })
  }
  handleLocationPermissionsState(permissionsState) {
    console.log('location permissions state', permissionsState);
    if(permissionsState.location == 'granted') {
      this.checkDeviceCompatability();
    } else if(permissionsState.location == 'denied') {
      //ALERT LOCATION REQUIRED
      Dialog.confirm({
        title: 'Location Permissions Required',
        message: 'Location permissions are required to use Well-AI Router. Please navigate to the app settings and enable location services.',
        okButtonTitle: 'Open Settings',
        cancelButtonTitle: 'Close Router'
      }).then(open => {
        if(open.value) {
          BackgroundGeolocation.showAppSettings().then(() => {
            this.checkDeviceCompatability();
          }).catch(err => {
            console.log('Settings Error',err);
          })
        } else {
          App.exitApp();
        }
      }).catch(err => {
        console.log('Dialog Error',err);
      })
    } else {
      this.requestLocationPermissions();
    }
  }
  requestLocationPermissions() {
    Geolocation.requestPermissions({permissions:['location','coarseLocation']}).then(permissionsState => {
      this.handleLocationPermissionsState(permissionsState);
    }).catch(err => {
      console.log('geo permissions request err',err);
      this.checkDeviceCompatability()
    })
  }
  checkDeviceCompatability() {
    Device.getInfo().then(info => {
      this.ds.device = info;
      console.log('Device Info',info);
      if(info.platform != 'web') {
        App.getInfo().then(app => {
          this.ds.device.app = app;
          this.ds.post('app/compatability',this.ds.device).then((ret:any) => {
            if(ret.failure) {
              Dialog.confirm({
                title: ret.failure.title,
                message: ret.failure.message,
                okButtonTitle: ret.failure.buttonText,
                cancelButtonTitle: 'Close Router'
              }).then(open => {
                console.log('Dialog Res',open);
                if(open.value) {
                  console.log('open url',ret.failure.url)
                } else {
                  App.exitApp();
                }
              }).catch(err => {
                console.log('Dialog Error',err);
              })
            } else {
              this.attemptLogin();
            }
          })
        }).catch(err => {
          console.log('App Info Err',err);
        })
      } else {
        console.log('Web version, device apis might be unavailable');
        this.attemptLogin();
      }
    }).catch(err => {
      console.log('Device Info Err',err);
    })
  }
  attemptLogin() {
    Storage.get({key:this.ds.app+'token'}).then(data => {
      if(data.value) {
        this.ds.token = data.value;
        this.ds.get('account/sign_in',null,true).then((data:any) => {
          if(data.user) {
            this.ds.user = data.user;
            this.router.navigateByUrl('menu/wells')
          }  else {
            this.ds.token = null;
            Storage.remove({key:this.ds.app+'token'})
            SplashScreen.hide().then(() => {
              //SET STATUS BAR
              if(this.ds.device.platform == 'android') {
                StatusBar.setStyle({style:Style.Dark});
                StatusBar.setBackgroundColor({color:'#3171e0'});
              } else if(this.ds.device.platform.platform == 'ios') {
                StatusBar.setStyle({style:Style.Default});
              }
            })
          }
        })
      } else {
        console.log('No Token Available');
        SplashScreen.hide().then(() => {
          //SET STATUS BAR
          if(this.ds.device.platform == 'android') {
            StatusBar.setStyle({style:Style.Dark});
            StatusBar.setBackgroundColor({color:'#3171e0'});
          } else if(this.ds.device.platform.platform == 'ios') {
            StatusBar.setStyle({style:Style.Default});
          }
        })
      }
    }).catch(err => {
      console.log('Token Retrieval Err',err);
      SplashScreen.hide().then(() => {
        //SET STATUS BAR
        if(this.ds.device.platform == 'android') {
          StatusBar.setStyle({style:Style.Dark});
          StatusBar.setBackgroundColor({color:'#3171e0'});
        } else if(this.ds.device.platform.platform == 'ios') {
          StatusBar.setStyle({style:Style.Default});
        }
      })
    })
  }
}
