import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoginService implements CanActivate {
  constructor(public ds: DataService, public router: Router) { }
  canActivate(): Observable<boolean> | boolean {
    if(this.ds.user && this.ds.token) {
      return false;
    } else {
      return true;
    }
  }
}
